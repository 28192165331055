import React, { useState } from 'react';
import WorksElem from "../components/WorksElem";
import WorksImg from "../assets/images/shape-works.webp";
import Agroland from "../assets/images/MCA_project/mcagroland.webp";
import CatHub from "../assets/images/CH_project/cathub.webp";
import Princess from "../assets/images/STP_project/savingtheprincess.webp";
import AirFun from "../assets/images/AFS_project/airfunstore.webp";
import Shanny from "../assets/images/Shanny_project/shanny.webp"
import DeliverNow from "../assets/images/DN_project/DeliverNow.webp"
import AnimatedPage from "../AnimatedPage";

const Works = () => {
    const [hoveredImage, setHoveredImage] = useState("saving-the-princess");

    const handleMouseOver = (imageName) => {
        setHoveredImage(imageName);
    };

    const handleMouseOut = () => {
        setHoveredImage(null);
    };
    document.body.style.overflow = 'auto';

    return (
        <AnimatedPage>
            <div className="works">
                <div className="image__container">
                    {hoveredImage === "saving-the-princess" && <img className="photo" src={Princess} alt="Saving the Princess game screenshot" />}
                    {hoveredImage === "cathub" && <img className="photo" src={CatHub} alt="CatHub screenshot" />}
                    {hoveredImage === "mc-agroland" && <img className="photo" src={Agroland} alt="Branding cup MC Agroland" />}
                    {hoveredImage === "air-fun-store" && <img className="photo" src={AirFun} alt="Air Fun Store logo" />}
                    {hoveredImage === "shanny" && <img className="photo" src={Shanny} alt="Shanny logo" />}
                    {hoveredImage === "delivernow" && <img className="photo" src={DeliverNow} alt="DeliverNow cover" />}
                </div>
                <div className="works__container--right">
                    <h1>WORKS</h1>
                    <div className="works__table">
                        <WorksElem title="saving the princess" type="web development" isLined={true} onMouseOver={() => handleMouseOver("saving-the-princess")} />
                        <WorksElem title="cathub" type="web development" isLined={true} onMouseOver={() => handleMouseOver("cathub")} />
                        <WorksElem title="mc agroland" type="brading" isLined={true} onMouseOver={() => handleMouseOver("mc-agroland")} />
                        <WorksElem title="air fun store" type="brading" isLined={true} onMouseOver={() => handleMouseOver("air-fun-store")} />
                        <WorksElem title="shanny" type="brading" isLined={true} onMouseOver={() => handleMouseOver("shanny")} />
                        <WorksElem title="delivernow" type="UX&UI design" isLined={false} onMouseOver={() => handleMouseOver("delivernow")} />
                    </div>
                </div>
                <img className="shape image--right" src={WorksImg} alt="Decorative shape" />
            </div>
        </AnimatedPage>
    );
}

export default Works;
